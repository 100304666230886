<template>
  <div style="height: 100%;" class="wrap">
    <div class="addSignName-wrap" id="signName">
      <div class="content">
        <div class="left">
          <div class="left-content">
            <ul class="color-list">
              <li
                class="color1"
                @click="setColor(1)"
                :class="num === 1 ? 'color1-s' : ''"
              >
                <span></span>
              </li>
              <li
                class="color2"
                @click="setColor(2)"
                :class="num === 2 ? 'color2-s' : ''"
              >
                <span></span>
              </li>
              <li
                class="color3"
                @click="setColor(3)"
                :class="num === 3 ? 'color3-s' : ''"
              >
                <span></span>
              </li>
            </ul>
            <div class="footer">
              <!-- <div class="clear"></div> -->
              <div class="delete" @click="clear"></div>
              <div class="save" @click="save">保存</div>
            </div>
          </div>
        </div>
        <div class="mid">
          <canvas
            :width="width"
            :height="height"
            :style="{ height: height + 'px', width: width + 'px' }"
          ></canvas>
        </div>
        <div class="right">
          <p>请保持手机横屏，并按照从左到右的方向居中签名</p>
        </div>
      </div>
      <img
        src="@/assets/imgs/invalid.svg"
        alt=""
        id="invalidImg"
        v-show="false"
      />
    </div>
  </div>
</template>
<script>
  import Draw from '@/utils/draw'
  import Api from '@/api/signName'
  import contractSignApi from '@/api/contractSign'
  import { QRCODE_STATUS } from '@/common/qrcodeStatusEnum'
  import { Toast } from 'vant'
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        QRCODE_STATUS,
        num: 1,
        img: '',
        imgSrc: '',
        creatidStatus: 0,
        width: 0,
        height: 0,
        loading: false,
        userId: null,
        colorMap: {
          1: '#111a34',
          2: '#4d81dd',
          3: '#f15643',
        },

        // 二维码的code
        code: undefined,
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
    mounted() {
      this.creditStatus = this.$route.query.creditStatus
      this.userId = this.$route.query.userId
      this.subId = this.$route.query.subId
      // Toast.setDefaultOptions({
      //   getContainer() {
      //     return document.querySelector('#signName')
      //   },
      // })
      this.width = document.documentElement.clientWidth - 115
      this.height = document.documentElement.clientHeight
      this.$nextTick(() => {
        this.initCanvas()
      })
    },
    async created() {
      this.code = this.$route.query?.code
      if (this.code) {
        await this.updateScanCodeStatus(this.QRCODE_STATUS.SCANNED)
      }
    },
    methods: {
      // 修改二维码的状态
      async updateScanCodeStatus(status) {
        try {
          const model = {
            code: this.code,
            status,
          }
          await contractSignApi.updateScanCodeStatus(model)
        } catch (error) {
          console.error(error)
        }
      },
      initCanvas() {
        const canvas = document.querySelector('canvas')
        this.draw = new Draw(canvas, 0)
        this.draw.setDefaultText()
      },
      setColor(num) {
        this.num = num
        this.draw.clear()
        this.draw.setColor(this.colorMap[num])
      },
      clear() {
        this.draw.clear()
      },
      gray(src, cb) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const invalidImgD = document.querySelector('#invalidImg')
        const img = new Image()
        img.src = src
        img.onload = () => {
          canvas.height = img.height
          canvas.width = img.width
          ctx.drawImage(img, 0, 0)
          const imgdata = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const { data } = imgdata
          /* 灰度处理：求r，g，b的均值，并赋回给r，g，b */
          for (let i = 0, n = data.length; i < n; i += 4) {
            const average = (data[i] + data[i + 1] + data[i + 2]) / 3
            data[i] = average
            data[i + 1] = average
            data[i + 2] = average
          }
          ctx.putImageData(imgdata, 0, 0)
          ctx.drawImage(
            invalidImgD,
            canvas.width / 2 - 819 / 2,
            canvas.height / 2 - 546 / 2,
            819,
            546
          )
          cb(canvas.toDataURL('image/png'))
        }
        /* 返回处理之后的src */
      },
      fillImg(src, cb) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const img = new Image()
        img.src = src
        img.onload = () => {
          console.log(img.width, img.height)
          canvas.width = img.width
          canvas.height = img.width
          ctx.drawImage(img, 0, img.width * 0.3)
          cb(canvas.toDataURL('image/png'))
        }
      },
      save() {
        if (this.draw.isText) {
          Toast('请输入签名')
          return
        }
        const base64Img = this.draw.rotate(-90).toDataURL('image/png')
        this.loading = true
        this.toast = Toast.loading({
          forbidClick: true,
          loadingType: 'spinner',
        })
        this.addUserSign(base64Img)
        // this.fillImg(base64Img, img => {
        //   this.addUserSign(img)
        // })
      },
      addUserSign(base64Img) {
        Api.addUserSign({
          base64Img,
          imgFormat: '.png',
          userId: this.userId,
          type: 1,
          systemId: this.systemInfo.systemId,
        })
          .then(() => {
            Toast('添加成功')
            // 添加成功后将状态改成制作成功
            this.updateScanCodeStatus(QRCODE_STATUS.MAKE_SIGN_NAME_SUCCESS)
            if (this.subId) {
              this.$store
                .dispatch('getMyInfo')
                .then(() => {
                  this.$router.go(-1)
                })
                .finally(() => {
                  this.draw.clear()
                })
              return
            }
            this.draw.clear()
          })
          .finally(() => {
            this.toast.clear()
          })
      },
    },
  }
</script>
<style lang="less" scoped>
  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addSignName-wrap {
    /deep/ .van-toast {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
    height: 100%;
    .content {
      height: 100%;
      display: flex;
      justify-content: center;
      .right {
        width: 47px;
        background: #fafafa;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #434751;
          line-height: 22px;
          transform: rotate(90deg);
          white-space: nowrap;
          letter-spacing: 5px;
        }
      }
      .left {
        padding: 24px 0;
        height: 100vh;
        width: 68px;
        // box-sizing: border-box;
        .left-content {
          border-right: 1px dashed #e2e4ea;
          display: flex;
          height: 100%;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          .color-list {
            li {
              width: 28px;
              height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 16px;
              border-radius: 50%;
              &:last-child {
                margin-bottom: 0;
              }
              span {
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
              }
            }
            .color1 {
              span {
                background: #111a34;
              }
            }
            .color2 {
              span {
                background: #4d81dd;
              }
            }
            .color3 {
              span {
                background: #f15643;
              }
            }
            .color1-s {
              border: 1px solid #111a34;
            }
            .color2-s {
              border: 1px solid #4d81dd;
            }
            .color3-s {
              border: 1px solid #f15643;
            }
          }
          .footer {
            padding-bottom: 120px;
            .clear {
              margin: 0 auto;
              width: 20px;
              height: 20px;
              background: url('../../assets/imgs/clear.png') no-repeat center;
              background-size: 20px 20px;
              margin-bottom: 24px;
              transform: rotate(90deg);
            }
            .delete {
              margin: 0 auto;
              width: 18px;
              height: 18px;
              background: url('../../assets/imgs/delete.png') no-repeat center;
              background-size: 18px 18px;
              transform: rotate(90deg);
              margin-bottom: 41px;
            }
            .save {
              width: 72px;
              height: 33px;
              background: #1676ff;
              border-radius: 4px;
              font-size: 16px;
              font-weight: 500;
              color: #ffffff;
              line-height: 22px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              white-space: pre-wrap;
              margin: 0 auto;
              transform: rotate(90deg);
              white-space: nowrap;
              letter-spacing: 2px;
            }
          }
        }
      }
      .mid {
        flex: 1;
      }
    }
  }
</style>
